import { BalHeading, BalText } from "@baloise/ds-react";
import {
    DeepPartial,
    Form,
    FormProps,
    FormValues,
    Language,
} from "@baloise-cfa/form-renderer-frontend";
import { graphql, navigate } from "gatsby";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import { CompletedCard, Navigation } from "@modules/shared/components";
import { PageNames } from "@modules/shared/enums";
import {
    getAddress,
    getNavigationPages,
    validateBusinessRules,
} from "@modules/shared/helpers";
import { useWtc } from "@modules/shared/hooks";
import { AppState, appState } from "@modules/shared/state";
import { PageProps, TabsStatus } from "@modules/shared/types";
import {
    driverFormConfig,
    SimulatorLayout,
    TabsLayout,
} from "@modules/simulator";

const DriverPage: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, ownPageObjects: allPages, language } = pageContext;
    const [appData, setAppData] = useRecoilState<AppState>(appState);
    const { t } = useTranslation();
    const { getWtcPage, businessRules } = useWtc(allPages);
    const innerLanguage: Language =
        language === "nl" ? Language.NL : Language.FR;
    const { nextPage, prevPage } = getNavigationPages(allPages, page);
    const [formProps, setFormProps] = useState<
        Pick<FormProps<FormValues>, "dirty" | "isValid">
    >({
        isValid: false,
        dirty: false,
    });

    const initialFormValues: DeepPartial<FormValues> = useMemo(() => {
        return {
            ...appData,
            Driver: {
                ...appData?.Driver,
                address: getAddress(appData?.Driver, innerLanguage),
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateFormState = (formValues: FormValues): void => {
        setAppData({
            ...appData,
            Driver: {
                ...formValues.Driver,
                city: {
                    value: formValues?.Driver?.address?.city?.data,
                },
                street: {
                    value: {
                        id: formValues?.Driver?.address?.street?.value,
                        name: formValues?.Driver?.address?.street?.title,
                    },
                },
                bus: formValues?.Driver?.address?.bus,
                houseNumber: formValues?.Driver?.address?.houseNumber,
            },
        });
    };

    const handleSubmit = (values: FormValues): void => {
        updateFormState(values);

        const wtcPage = getWtcPage(
            validateBusinessRules(businessRules, appData).action,
        );

        if (wtcPage) {
            navigate(wtcPage.paths[language]);
            return;
        }
        if (nextPage) {
            navigate(nextPage.paths[language]);
        }
    };

    const handleOnChange = (
        values: FormValues,
        props: Pick<FormProps<FormValues>, "dirty" | "isValid">,
    ): void => {
        setFormProps(props);
        updateFormState(values);
    };

    const renderHeaderComponent = useCallback(
        (tabsStatus: TabsStatus) => {
            const showCompleteCard = formProps.dirty
                ? formProps.isValid
                : tabsStatus && tabsStatus[page.name]?.isValid;

            if (showCompleteCard) {
                return (
                    <CompletedCard
                        nextPage={nextPage}
                        currentPage={page}
                        language={language}
                    />
                );
            }
        },
        [language, nextPage, page, formProps],
    );

    return (
        <>
            <SimulatorLayout
                title={t("simulator.driver.title")}
                page={page}
                language={language}
                allPages={allPages}
            >
                <TabsLayout
                    title={t("simulator.tabs.title")}
                    description={t("simulator.tabs.description")}
                    language={language}
                    allPages={allPages}
                    activeTabName={PageNames.Driver}
                >
                    {({ tabsStatus }) => {
                        return (
                            <>
                                <div className="intro">
                                    <BalHeading level="h1">
                                        {t("simulator.driver.title")}
                                    </BalHeading>
                                    <BalText>
                                        {t("simulator.driver.intro")}
                                    </BalText>
                                </div>
                                <Form
                                    scrollToFieldError
                                    formContext={{
                                        t,
                                        nameSpace: PageNames.Driver,
                                        fieldWrapper: {
                                            optionalLabel: "all.optional",
                                        },
                                    }}
                                    initialValues={initialFormValues}
                                    onSubmit={handleSubmit}
                                    onChange={handleOnChange}
                                    fields={driverFormConfig.fields}
                                    errorMessageCardTitle={
                                        t("all.errormessage.title") as string
                                    }
                                    errorMessageCardSubTitle={
                                        t("all.errormessage.text") as string
                                    }
                                    headerComponent={() =>
                                        renderHeaderComponent(tabsStatus)
                                    }
                                    enableReinitialize
                                >
                                    <Navigation
                                        t={t}
                                        language={language}
                                        prevPage={prevPage}
                                        nextPage={nextPage}
                                        nextColor="info"
                                    />
                                </Form>
                            </>
                        );
                    }}
                </TabsLayout>
            </SimulatorLayout>
        </>
    );
};

export default DriverPage;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
